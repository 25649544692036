
import { Subject, filter, map, mergeMap, take, tap } from 'rxjs';
import { BehaviorSubject} from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable()
export class StoresService {
  public myStores = new BehaviorSubject<any[]>(null);
  public adminMadeBooking = new Subject<any>();
  public refreshClientList = new Subject<any>(); // workaround to refresh client list after updating a client (nested components)

  constructor(private api: ApiGatewayService, private activatedRoute: ActivatedRoute, private http: HttpClient) {}

  getMyStores() {
    return this.api.get(`stores`).pipe(
      tap((res) => {
        const sorted = res.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
        this.myStores.next(sorted);
      })
    );
  }

  getActiveStore() {
    const slug = this.activatedRoute.snapshot?.firstChild.params?.slug;
    return this.myStores.pipe(map((arr) => arr?.find((v) => v.slug === slug)), filter(v => v ? true : false));
  }

  getStoreId(slug) {
    return this.myStores.pipe(map((arr) => arr?.find((v) => v.slug === slug)?.id), filter(v => v ? true : false), take(1));
  }

  getStore(slug) {
    return this.getStoreId(slug).pipe(mergeMap((storeId) => {
      return this.api.get(`stores/${storeId}`);
    }));
  }

  getStoreServices(storeId, params = {}) {
    return this.api.get(`stores/${storeId}/services?` + this.api.serializeParams(params));
  }

  getStoreProviders(storeId) {
    return this.api.get(`stores/${storeId}/providers`);
  }

  saveStoreProvidersWorkplan(providerId, payload) {
    return this.api.put(`providers/${providerId}/workplan`, payload);
  }

  createProvider(storeId, payload) {
    return this.api.post(`stores/${storeId}/providers`, payload);
  }

  saveStoreProviders(storeId, payload) {
    return this.api.put(`stores/${storeId}/providers`, payload);
  }

  saveProviderServices(providerId, payload) {
    return this.api.put(`providers/${providerId}/services`, payload);
  }

  updateProvider(providerId, payload) {
    return this.api.put(`providers/${providerId}`, payload);
  }

  removeProvider(storeId, payload) {
    return this.api.put(`stores/${storeId}/provider/remove`, payload);
  }

  getStoreCalendar(storeId, payload) {
    return this.api.post(`stores/${storeId}/calendar`, payload);
  }

  getFutureEventsCounter(storeId, providerId) {
    return this.api.get(`stores/${storeId}/${providerId}/future-events-counter`);
  }

  saveStoreServices(storeId, payload) {
    return this.api.put(`stores/${storeId}/services`, payload);
  }

  saveStoreWorkplan(storeId, payload) {
    return this.api.put(`stores/${storeId}/workplan`, payload);
  }

  createStore(payload) {
    return this.api.post(`stores`, payload);
  }

  editStore(storeId, payload) {
    return this.api.put(`stores/${storeId}`, payload);
  }

  editGooglePlaceId(storeId, googlePlaceId, googlePlaceName, googlePlaceAddress, googlePlacePhoneNumber) {
    return this.api.put(`stores/${storeId}/googleplace`, { googlePlaceId, googlePlaceName, googlePlaceAddress, googlePlacePhoneNumber });
  }

  createBookingAdmin(storeId, payload) {
    return this.api.post(`stores/${storeId}/bookings/multiple/admin`, payload).pipe(tap(() => { this.adminMadeBooking.next(true); }));
  }

  getBookingAvailabilityAdmin(storeId, payload) {
    return this.api.post(`stores/${storeId}/availability/admin`, payload);
  }

  editBooking(bookingId, payload) {
    return this.api.put(`bookings/multiple/${bookingId}`, payload);
  }

  noShowBooking(bookingId, payload) {
    return this.api.put(`bookings/${bookingId}/noshow`, payload);
  }

  deleteBooking(bookingId, deleteAll = false, notifyClient = true) {
    return this.api.delete(`bookings/${bookingId}`, { deleteAll: deleteAll, notifyClient });
  }

  deleteStore(storeId) {
    return this.api.delete(`stores/${storeId}`);
  }

  createHoliday(payload) {
    return this.api.post(`holidays`, payload);
  }
  editHoliday(holidayId, payload) {
    return this.api.put(`holidays/${holidayId}`, payload);
  }

  deleteHoliday(holidayId, deleteAll = false) {
    return this.api.delete(`holidays/${holidayId}`, { deleteAll: deleteAll });
  }

  googleCodeConnectCalendarToStore(storeId, code) {
    return this.api.post('stores/' + storeId + '/connect/gcalendar', { code: code });
  }

  createImportContactsFile(storeId, fileUrl, fileName, fileType) {
    return this.api.post('stores/' + storeId + '/import/file', { fileUrl, fileName, fileType, type: 'merchantClients' });
  }

  getImportContactsFileStatus(storeId) {
    return this.api.get('stores/' + storeId + '/import/file?type=merchantClients');
  }

  googleCodeDisconnectCalendarToStore(storeId) {
    return this.api.delete('stores/' + storeId + '/connect/gcalendar');
  }

  getStoreStats(storeSlug) {
    return this.api.get(`stores/${storeSlug}/stats`);
  }

  getMetabaseUrl(storeSlug) {
    return this.api.get(`stores/${storeSlug}/stats/metabase`);
  }

  getStoreUsers(storeId) {
    return this.api.get(`users/store/${storeId}`);
  }

  createStoreUser(payload) {
    return this.api.post(`users`, payload);
  }

  editStoreUser(userId, payload) {
    return this.api.put(`users/${userId}`, payload);
  }
  editStoreUserInfo(userId, payload) {
    return this.api.put(`users/${userId}/info`, payload);
  }

  deleteStoreUser(userId) {
    return this.api.delete(`users/${userId}`);
  }

  resendInvitationStoreUser(userId) {
    return this.api.post(`users/${userId}/resend/invite`);
  }

  compareWorkplans(storeWorkplan, providerWorkplan) {
    if (!providerWorkplan) {
      return true;
    }

    let isEqual = true;

    Object.keys(storeWorkplan).forEach((day) => {
      if (JSON.stringify(storeWorkplan[day]) !== JSON.stringify(providerWorkplan[day])) {
        isEqual = false;
      }
    });

    return isEqual;
  }

  getStoreNotificationsExtras(storeSlug) {
    return this.api.get(`stores/${storeSlug}/notificationsExtras`);
  }

  enableAddon(storeId: number, payload: { addon: string; }) {
    return this.api.put(`stores/${storeId}/addons`, payload);
  }

  disableAddon(storeId: number, payload: { addon: string; }) {
    return this.api.delete(`stores/${storeId}/addons`, payload);
  }

  updateNotificationExtras(storeSlug, payload) {
    return this.api.put(`stores/${storeSlug}/notificationsExtras`, payload);
  }

  connectMoloni(storeSlug, code = '', connect = true) {
    return this.api.post(`stores/${storeSlug}/connect/moloni`, { code, connect });
  }

  getConnectURL(storeId) {
    return this.api.get(`stores/${storeId}/stripe/connect`);
  }

  createInvoice(storeId, payload) {
    return this.api.post(`stores/${storeId}/invoice`, payload);
  }

  getListOfProductsAndPaymentMethodsForInvoice(storeId) {
    return this.api.get(`stores/${storeId}/invoice/productspayments`);
  }

  getLoyaltyProgram(storeSlug) {
    return this.api.get(`stores/${storeSlug}/loyaltyConfig`);
  }

  updateLoyaltyProgram(storeSlug, payload) {
    return this.api.put(`stores/${storeSlug}/loyaltyConfig`, payload);
  }

  getBookingFrequencyForCalendar(bookingFrequency) {
    switch (bookingFrequency) {
      case 20:
      case 40:
        return 10;
      case 45:
      case 75:
      case 105:
        return 15;
      case 60:
      case 90:
      case 120:
      case 150:
        return 30;
      default:
        return bookingFrequency;
    }
  }

  getStripeConnectDetails(storeId) {
    return this.api.get(`stores/${storeId}/stripe/data`);
  }

  isPaymentsActive(store) {
    return ( // old mbway
      (store.activeMBWay && !store.isEuPagoWithStripeConnect)
      // new mbway or stripe
      || (
        (
          store.stripePaymentsEnabled || store.mbrefPaymentsEnabled ||
          (store.activeMBWay && store.isEuPagoWithStripeConnect)
        )
        &&
        store.stripeChargesEnabled
      )
    );
  }

}
